import { useState } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { ProfileAbout } from "@circle-react/components/ProfileModalV3/Body/About";
import { ProfileComments } from "@circle-react/components/ProfileModalV3/Body/Comments";
import { ProfilePosts } from "@circle-react/components/ProfileModalV3/Body/Posts";
import { ProfileHeader } from "@circle-react/components/ProfileModalV3/Body/ProfileHeader";
import { ProfileSpaces } from "@circle-react/components/ProfileModalV3/Body/Spaces";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";
import { useSmOrMdScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { TabItem } from "./TabItem";

type Tab = "posts" | "comments" | "spaces" | "about";

export const PROFILE_MODAL_CONTAINER_ID = "profile-modal-scrollable-target";

export const Body = () => {
  const isSmallScreen = useSmOrMdScreenMediaQuery();
  const { member, isFullPageView } = useProfileContext();
  const [activeTab, setActiveTab] = useState<Tab>(
    isSmallScreen ? "about" : "posts",
  );

  const ComponentMap: Record<Tab, React.ComponentType> = {
    posts: ProfilePosts,
    comments: ProfileComments,
    spaces: ProfileSpaces,
    about: ProfileAbout,
  };

  const ComponentToRender = ComponentMap[activeTab];

  return (
    <div
      className={classNames("bg-primary flex flex-col overflow-auto", {
        "border-primary w-full rounded-xl border p-4 md:w-2/3 md:p-8":
          isFullPageView,
        "col-span-12 md:col-span-9": !isFullPageView,
      })}
    >
      <ProfileHeader />
      <div className="my-6 flex items-center space-x-2 overflow-x-auto">
        {isSmallScreen && (
          <TabItem
            onClick={() => setActiveTab("about")}
            title={t("profile_drawer.about")}
            isActive={activeTab === "about"}
          />
        )}
        <TabItem
          onClick={() => setActiveTab("posts")}
          title={t("profile_drawer.posts.title")}
          isActive={activeTab === "posts"}
          count={member.posts_count}
        />
        <TabItem
          onClick={() => setActiveTab("comments")}
          title={t("profile_drawer.comments.title")}
          isActive={activeTab === "comments"}
          count={member.comments_count}
        />
        <TabItem
          onClick={() => setActiveTab("spaces")}
          title={t("profile_drawer.spaces.title")}
          isActive={activeTab === "spaces"}
          count={member.spaces_count}
        />
      </div>
      <div
        id={PROFILE_MODAL_CONTAINER_ID}
        className="flex-1 overflow-auto pb-6"
      >
        <ComponentToRender />
      </div>
    </div>
  );
};
