import { useChatRoomContext } from "../useChatRoomContext";
import { useChatStore } from "./useChatStore";

const selectThreads = (state: any) => state.threads;
const selectChatRoom = (uuid: string) => (state: any) =>
  state.chatRoomsMap[uuid];

export const useThreadsStore = () => useChatStore(selectThreads);

export const useChatRoomStoreStates = () => {
  const { createNewChatRoom } = useChatStore();
  const { chatRoomUuid } = useChatRoomContext();
  const chatRoom = useChatStore(selectChatRoom(chatRoomUuid));
  return chatRoom ? chatRoom : createNewChatRoom(chatRoomUuid);
};

export const useChatRoomStoreActions = () => {
  const { roomActions } = useChatStore();
  const { chatRoomUuid } = useChatRoomContext();
  return {
    setChatRoomMessages: (messages: any[]) =>
      roomActions.setChatRoomMessages(chatRoomUuid, messages),
    setIsLoading: (isLoading: boolean) =>
      roomActions.setIsLoading(chatRoomUuid, isLoading),
    setIsInitialPageLoaded: (isLoaded: boolean) =>
      roomActions.setIsInitialPageLoaded(chatRoomUuid, isLoaded),
    setHasNextPage: (hasNextPage: boolean) =>
      roomActions.setHasNextPage(chatRoomUuid, hasNextPage),
    setHasPrevPage: (hasPrevPage: boolean) =>
      roomActions.setHasPrevPage(chatRoomUuid, hasPrevPage),
    setScrollInfo: (scrollInfo: any) =>
      roomActions.setScrollInfo(chatRoomUuid, scrollInfo),
    setFirstMessageId: (id: number | null) =>
      roomActions.setFirstMessageId(chatRoomUuid, id),
    setLastMessageId: (id: number | null) =>
      roomActions.setLastMessageId(chatRoomUuid, id),
    setShouldShowLatestMessageBtn: (shouldShowLatestMessageBtn: boolean) =>
      roomActions.setShouldShowLatestMessageBtn(
        chatRoomUuid,
        shouldShowLatestMessageBtn,
      ),
    setTopMessageId: (id: number | null) =>
      roomActions.setTopMessageId(chatRoomUuid, id),
    setActiveMessageId: (id: string | null) =>
      roomActions.setActiveMessageId(chatRoomUuid, id),
    resetChatRoom: () => roomActions.resetChatRoom(chatRoomUuid),
  };
};

export const useChatRoomStore = () => ({
  ...useChatRoomStoreStates(),
  ...useChatRoomStoreActions(),
});
