import type { MouseEvent } from "react";
import classnames from "classnames";
import { t } from "i18n-js";
import {
  useCurrentPostContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { toLocaleString } from "@circle-react/helpers/number";
import { isImagePost } from "@circle-react/helpers/postHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";

export interface LikeButtonProps {
  name: string | null;
  onLikeToggle: (event: MouseEvent<HTMLButtonElement>) => void;
  is_liked_by_current_user: boolean;
  user_likes_count: number;
  isOrCanBeCommunityMember: boolean;
  className?: string;
  likedSpanClassName?: string;
  showCount?: boolean;
  iconClassName?: string;
}

export const LikeButton = ({
  name,
  onLikeToggle,
  is_liked_by_current_user: isLikedByCurrentUser,
  user_likes_count: likesCount,
  isOrCanBeCommunityMember,
  className,
  iconClassName = "",
  likedSpanClassName,
  showCount = true,
}: LikeButtonProps) => {
  const { isViewOnlyMasquerading } = usePunditUserContext();
  const { record: post } = useCurrentPostContext();
  const { isV3Enabled } = useIsV3();

  const getTooltipContent = (isLiked: any) => {
    const key = isLiked ? "post.unlike_description" : "post.like_description";
    return t(key, { name: name ?? "" });
  };

  return (
    <div className={classnames("col-span-1", className)}>
      <TippyV2
        placement="bottom"
        content={getTooltipContent(isLikedByCurrentUser)}
        interactive={false}
      >
        <button
          type="button"
          onClick={onLikeToggle}
          aria-label={t("like_post_aria_label", {
            name: name ?? "",
          })}
          className={classnames(
            "action-item action-link action-like post__actions--like text-default focus-visible:outline-secondary group flex items-center justify-center rounded-md text-center focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
            {
              liked: isLikedByCurrentUser,
              disabled: !isOrCanBeCommunityMember || isViewOnlyMasquerading,
              "hover:text-dark": !isLikedByCurrentUser,
              "hover:bg-tertiary min-w-[2.5rem] p-1": !isImagePost(post),
            },
          )}
        >
          <span
            className={classnames(
              "action__icon inline-flex align-text-top",
              isLikedByCurrentUser && likedSpanClassName,
            )}
          >
            {!isV3Enabled ? (
              <Icon
                type="like"
                className={classnames(
                  "mr-1",
                  {
                    "!text-brand": isLikedByCurrentUser,
                  },
                  iconClassName,
                )}
                useWithFillCurrentColor
              />
            ) : (
              <Icon
                className={classnames("mr-1", iconClassName)}
                type={
                  isLikedByCurrentUser
                    ? "24-heart-red-fill"
                    : "24-heart-outline"
                }
              />
            )}
          </span>
          {showCount && (
            <span
              className={classnames("count text-base", {
                "!text-brand": isLikedByCurrentUser,
              })}
            >
              {toLocaleString(likesCount)}
            </span>
          )}
        </button>
      </TippyV2>
    </div>
  );
};
