import classNames from "classnames";
import { t } from "i18n-js";
import { AvatarWithProgress } from "@circle-react/components/Leaderboard/Dashboard/MyProfile/AvatarWithProgress";
import { useGamificationMember } from "@circle-react/components/Profile/HoverCard/useGamificationMember";
import { ProfileActions } from "@circle-react/components/ProfileModalV3/Body/ProfileHeader/Actions";
import { ProfileInfoItem } from "@circle-react/components/ProfileModalV3/Common/ProfileInfoItem";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";
import { formatDateString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { useSmOrMdScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { MemberTags } from "@circle-react-shared/uikit/MemberTags";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const ProfileHeader = () => {
  const { member, isLoading } = useProfileContext();
  const isSmallScreen = useSmOrMdScreenMediaQuery();

  const { data: gamificationMembers } = useGamificationMember({
    enabled: !isLoading,
    member_ids: [member?.id],
  });
  const gamificationMember = gamificationMembers?.[0];

  const visibleMemberTags = member?.member_tags?.filter(
    (memberTag: any) =>
      memberTag?.is_public && memberTag?.display_locations?.profile_page,
  );
  const hasVisibleMemberTags = visibleMemberTags?.length > 0;

  return (
    <div className="flex flex-col items-center md:flex-row md:space-x-5 md:pt-0">
      <AvatarWithProgress
        name={member.name}
        avatarUrl={member.avatar_url}
        size="large"
        level={gamificationMember?.current_level}
        progress={gamificationMember?.level_progress}
      />
      <div className="mt-6 flex flex-1 flex-col items-center md:mt-0 md:items-start">
        <div
          className={classNames(
            "flex flex-1 flex-row justify-between md:w-full",
            member.headline ? "items-start" : "items-end",
          )}
        >
          <div className="flex flex-col items-center space-y-1 md:items-start">
            <Typography.LabelLg weight="semibold" color="text-dark">
              {member.name}
            </Typography.LabelLg>
            {member.headline && (
              <Typography.LabelSm weight="regular" color="text-dark">
                {member.headline}
              </Typography.LabelSm>
            )}
          </div>
          {!isSmallScreen && <ProfileActions />}
        </div>
        <div className="mt-2 flex flex-col items-center space-x-4 space-y-1 md:flex-row md:space-y-0">
          {member.last_seen_at && (
            <ProfileInfoItem
              iconType="16-clock"
              info={t("profile_drawer.last_seen", {
                last_seen: dateStringToTimeAgo(member.last_seen_at),
              })}
              dataTestId="profile-member-email"
              className="text-light"
            />
          )}
          <ProfileInfoItem
            iconType="16-calendar"
            info={t("profile_drawer.member_since", {
              member_since: formatDateString(member.created_at, {
                month: "long",
                year: "numeric",
                day: "numeric",
              }),
            })}
            dataTestId="profile-member-since"
            className="text-light"
          />
        </div>
        {hasVisibleMemberTags && (
          <div className="pt-3">
            <MemberTags limit={Infinity} memberTags={visibleMemberTags} />
          </div>
        )}
        {isSmallScreen && (
          <div className="mt-4">
            <ProfileActions />
          </div>
        )}
      </div>
    </div>
  );
};
