import { useState } from "react";
import { t } from "i18n-js";
import { useProfileInfo } from "@/react/hooks/profile/useProfileInfo";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { PROFILE_PAGES } from "@circle-react/helpers/memberHelper";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Drawer } from "@circle-react-uikit/Drawer";
import { ProfileBody } from "./ProfileBody";
import { ProfileMessaging } from "./ProfileMessaging";

const pageParams = {
  page_name: PROFILE_PAGES.PROFILE_VIEW,
};

export interface ProfileDrawerProps {
  memberPublicId: string;
  params?: {
    shouldOpenInNewTab?: boolean;
  };
}

export const ProfileDrawerComponent = ({
  memberPublicId,
  params,
}: ProfileDrawerProps) => {
  const {
    getProfileInfoQuery: { data: member, isLoading, isError },
  } = useProfileInfo({
    publicId: memberPublicId,
    params: pageParams,
  });

  const { shouldOpenInNewTab } = params || {};
  const [chatRoomUuid, setChatRoomUuid] = useState<string>("");
  const drawer = useModal();
  const isSmScreen = useSmScreenMediaQuery();

  const handleClose = () => {
    setChatRoomUuid("");
    void drawer.hide();
  };

  const handleOpenMessaging = (uuid: string) => {
    if (shouldOpenInNewTab) {
      setChatRoomUuid(uuid);
    } else {
      handleClose();
    }
  };

  const handleCloseMessaging = () => {
    setChatRoomUuid("");
  };

  return (
    <Drawer
      noWrapperPadding
      fixedHeader={!chatRoomUuid}
      maskClosable
      title={t("profile")}
      open={drawer.visible}
      onClose={handleClose}
      hideHeader={isSmScreen || !!chatRoomUuid}
    >
      {chatRoomUuid ? (
        <ProfileMessaging
          uuid={chatRoomUuid}
          title={member?.name ?? ""}
          onClose={handleCloseMessaging}
        />
      ) : (
        <ProfileBody
          isLoading={isLoading}
          isError={isError}
          isSmScreen={isSmScreen}
          member={member}
          drawer={drawer}
          memberPublicId={memberPublicId}
          shouldOpenInNewTab={shouldOpenInNewTab}
          onOpenMessaging={handleOpenMessaging}
        />
      )}
    </Drawer>
  );
};
