import { create } from "zustand";
import { createChatRoomsSlice } from "./slices/createChatRoomsSlice";
import type { ChatRoomsStoreProps } from "./slices/createChatRoomsSlice";
import { createThreadsSlice } from "./slices/createThreadsSlice";
import type { ChatThreadsStore } from "./slices/createThreadsSlice";
import { createUnreadMessagesSlice } from "./slices/createUnreadMessagesSlice";
import type { UnreadMessagesSliceProps } from "./slices/createUnreadMessagesSlice";

type ChatStoreProps = ChatRoomsStoreProps &
  ChatThreadsStore &
  UnreadMessagesSliceProps;

export const useChatStore = create<ChatStoreProps>()((...a) => ({
  ...createChatRoomsSlice(...a),
  ...createThreadsSlice(...a),
  ...createUnreadMessagesSlice(...a),
}));
