import { Icon } from "@circle-react/components/shared/Icon";
import { dateComponents } from "@circle-react/helpers/dateTimeHelpers/dateComponents";
import { dateToTimeString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import type { RecurringEvent } from "@circle-react/types/Event";
import { Typography } from "@circle-react-uikit/Typography";

interface ConfirmationDateProps {
  recurringEvent: RecurringEvent;
}

export const RecurringEventConfirmationDate = ({
  recurringEvent,
}: ConfirmationDateProps) => {
  const {
    starts_at: startsAt,
    ends_at: endsAt,
    time_zone: timeZone,
  } = recurringEvent || {};

  const { dayOfMonth, shortMonthString } = dateComponents(startsAt);
  const startTime = dateToTimeString(startsAt, timeZone);
  const endTime = dateToTimeString(endsAt, timeZone);

  return (
    <div className="bg-tertiary flex w-fit items-center justify-center rounded-md">
      <div className="flex px-4 py-2">
        <Icon
          type="16-calendar"
          className="text-default mr-2 h-4 !w-4 font-medium"
          size={16}
        />
        <Typography.LabelSm weight="medium" color="text-default">
          {`${dayOfMonth} ${shortMonthString}, ${startTime} - ${endTime}`}
        </Typography.LabelSm>
      </div>
    </div>
  );
};
