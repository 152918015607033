import { t } from "i18n-js";
import InfiniteScroll from "react-infinite-scroll-component";
import { PROFILE_MODAL_CONTAINER_ID } from "@circle-react/components/ProfileModalV3/Body";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";
import { LoaderRow } from "@circle-react/components/shared/LoaderRow";
import { useProfileSpaces } from "@circle-react/hooks/profile/useProfileSpaces";
import EmptyState from "../EmptyState";
import { Space } from "./Space";

export const ProfileSpaces = () => {
  const { member, onClose, isFullPageView } = useProfileContext();
  const { spaces, isLoading, fetchNextPage, hasNextPage } = useProfileSpaces({
    memberPublicId: member.public_uid,
  });

  if (isLoading) {
    return <LoaderRow />;
  }

  if (spaces.length < 1) {
    return (
      <EmptyState
        title={t("profile_drawer.spaces.empty_state.title")}
        subtitle={t("profile_drawer.spaces.empty_state.subtitle")}
      />
    );
  }

  return (
    <div className="h-full overflow-auto" id="profile-spaces-scrollable-target">
      <InfiniteScroll
        scrollThreshold={0.5}
        next={fetchNextPage}
        hasMore={!!hasNextPage}
        loader={<LoaderRow />}
        dataLength={spaces.length}
        scrollableTarget={isFullPageView ? "panel" : PROFILE_MODAL_CONTAINER_ID}
      >
        {spaces.map(space => (
          <Space key={space.id} onClose={() => onClose?.()} space={space} />
        ))}
      </InfiniteScroll>
    </div>
  );
};
