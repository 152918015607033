import PropTypes from "prop-types";
import { t } from "i18n-js";
import { RailBarPortal } from "@circle-react/components/Layout/StandardLayoutV2/RailBarPortal";
import { DIRECT_CHAT_ROOM } from "@circle-react/components/constants";
import { PROFILE_PAGES } from "@circle-react/helpers/memberHelper";
import { useChatParticipantsApi } from "@circle-react/hooks/chatsV2/useChatParticipantsApi";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";
import { useProfileInfo } from "@circle-react/hooks/profile/useProfileInfo";
import { useElementHeight } from "@circle-react/hooks/utils/useElementHeight";
import { Rail } from "@circle-react-uikit/Rail";
import { Content } from "./Content";
import { Header } from "./Header";
import "./styles.scss";

const params = {
  page_name: PROFILE_PAGES.PROFILE_VIEW,
};

const RailBar = ({ chatRoom = {}, chatRoomUuid, refetchChatRoom }) => {
  const {
    chat_room_name,
    chat_room_kind,
    chat_room_description,
    current_participant: currentParticipant,
  } = chatRoom;

  const isDirect = chat_room_kind === DIRECT_CHAT_ROOM;

  const {
    chatParticipants,
    isLoading: isChatParticipantsLoading,
    totalCount: totalParticipantsCount,
  } = useChatParticipantsApi(chatRoomUuid); //Disabling participants request in case of direct messaging

  const otherParticipant = (chatParticipants || []).find(
    participant => participant.id !== currentParticipant?.id,
  );

  const { toggleShowRailBar } = useRailbar();
  const {
    getProfileInfoQuery: { data: member = {}, isLoading: isProfileLoading },
  } = useProfileInfo({
    publicId: otherParticipant?.user_public_uid,
    params,
  });

  const { ref: headerRef, height: headerHeight } = useElementHeight();

  return (
    <RailBarPortal
      isLoading={
        isDirect
          ? isProfileLoading || isChatParticipantsLoading
          : isChatParticipantsLoading
      }
      loaderVariant={isDirect ? "rail-profile" : "rail-member-list"}
    >
      <div ref={headerRef}>
        <Rail.Header>
          <Header
            title={isDirect ? t("profile") : chat_room_name}
            memberCount={totalParticipantsCount}
            isDirect={isDirect}
            description={chat_room_description}
            onClose={toggleShowRailBar}
          />
        </Rail.Header>
      </div>
      <Rail.Content noPadding heightGap={headerHeight}>
        <Content
          chatRoom={chatRoom}
          isDirect={isDirect}
          otherParticipant={otherParticipant}
          member={member}
          refetchChatRoom={refetchChatRoom}
        />
      </Rail.Content>
    </RailBarPortal>
  );
};

export { RailBar };

RailBar.propTypes = {
  chatRoom: PropTypes.object,
  refetchChatRoom: PropTypes.func,
  chatRoomUuid: PropTypes.string,
};
