import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ProfileModalV3 } from "@circle-react/components/ProfileModalV3";
import { usePunditUserContext } from "@circle-react/contexts";
import { ProfileDrawerComponent } from "./ProfileDrawerComponent";
import type { ProfileDrawerProps } from "./ProfileDrawerComponent";
import "./styles.scss";

const ProfileDrawer = ModalManager.create(
  ({ memberPublicId, params }: ProfileDrawerProps) => {
    const { currentCommunitySettings } = usePunditUserContext();
    const isProfileModalEnabled = Boolean(
      currentCommunitySettings?.profile_modal_enabled,
    );
    return isProfileModalEnabled ? (
      <ProfileModalV3 memberPublicId={memberPublicId} params={params} />
    ) : (
      <ProfileDrawerComponent memberPublicId={memberPublicId} params={params} />
    );
  },
);

export const useProfileDrawer = () => useModal(ProfileDrawer);
