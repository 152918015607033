import { useModal } from "@ebay/nice-modal-react";
import { t } from "i18n-js";
import { ProfileV3 } from "@circle-react/components/ProfileModalV3/Profile";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { ModalCloseButton } from "@circle-react-shared/uikit/ModalV2/ModalCloseButton";
import { Typography } from "@circle-react-shared/uikit/Typography";

export interface ProfileModalProps {
  memberPublicId: string;
  params?: {
    shouldOpenInNewTab?: boolean;
  };
}

export const ProfileModalV3 = ({ memberPublicId }: ProfileModalProps) => {
  const modal = useModal();
  return (
    <Modal
      isOpen={modal.visible}
      onClose={() => {
        modal.remove();
      }}
      contentPortalId="post-modal-portal"
    >
      <Modal.Overlay />
      <Modal.Content size="full" className="max-w-screen-xl">
        <div className="border-primary text-dark flex justify-between border-b !px-9 !py-4">
          <Typography.TitleSm weight="semibold">
            {t("profile")}
          </Typography.TitleSm>
          <ModalCloseButton onClick={() => modal.remove()} />
        </div>
        <Modal.Body className="!md:px-9 !md:pt-9 grid grid-flow-row-dense grid-cols-12 gap-x-6 !overflow-hidden !pb-0 !pt-6">
          <ProfileV3
            onClose={() => modal.remove()}
            memberPublicId={memberPublicId}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
