import { Body } from "@circle-react/components/ProfileModalV3/Body";
import { Sidebar } from "@circle-react/components/ProfileModalV3/Sidebar";
import { ProfileContextProvider } from "@circle-react/components/ProfileModalV3/context";
import { PROFILE_PAGES } from "@circle-react/helpers/memberHelper";
import { useProfileInfo } from "@circle-react/hooks/profile/useProfileInfo";
import { Loader } from "./Loader";

const pageParams = {
  page_name: PROFILE_PAGES.PROFILE_VIEW,
};

export const ProfileV3 = ({
  memberPublicId,
  isFullPageView = false,
  onClose,
}: {
  memberPublicId: string;
  isFullPageView?: boolean;
  onClose?: () => void;
}) => {
  const {
    getProfileInfoQuery: { data: member, isLoading },
  } = useProfileInfo({
    publicId: memberPublicId,
    params: pageParams,
  });
  return (
    <ProfileContextProvider
      isFullPageView={isFullPageView}
      member={member}
      isLoading={isLoading}
      onClose={onClose}
    >
      {member ? (
        <>
          <Body />
          <Sidebar />
        </>
      ) : (
        <Loader />
      )}
    </ProfileContextProvider>
  );
};
