import classNames from "classnames";
import { t } from "i18n-js";
import { useRouteMatch } from "react-router";
import {
  useCurrentPostContext,
  useCurrentSpaceContext,
} from "@circle-react/contexts";
import { isImagePost } from "@circle-react/helpers/postHelpers";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";
import { PostActions } from "./PostActions";
import { PostMetaInfo } from "./PostMetaInfo";
import { PostName } from "./PostName";

export interface PostHeaderProps {
  hideAuthorHeadline?: boolean;
  hideBookmarkButton?: boolean;
  hidePinnedToTopIcon?: boolean;
  hideShareLink?: boolean;
  isRenderedOutsideTheSpace?: boolean;
  metaInfoWrapperClassName?: string;
  onChange: (data: any) => void;
  onPostDestroy: (data: any) => void;
  post: any;
  refetchPosts?: () => void;
  tagsLimit: number;
  tagsShowMoreLabelVariant?: "words" | "numbers";
}

export const PostHeader = ({
  hideAuthorHeadline = false,
  hideBookmarkButton = false,
  hidePinnedToTopIcon = false,
  hideShareLink = false,
  isRenderedOutsideTheSpace = false,
  metaInfoWrapperClassName,
  onChange,
  onPostDestroy,
  post,
  refetchPosts,
  tagsLimit,
  tagsShowMoreLabelVariant,
}: PostHeaderProps) => {
  const { data: space } = useCurrentSpaceContext();
  const { usedIn, isUsedInMinimalTipTapViewModal } = useCurrentPostContext();
  const doesPostHaveCoverImage = post?.cover_image_url;
  const isSmScreen = useSmScreenMediaQuery();
  const isHomePage = useRouteMatch("/home")?.isExact;
  const isFeedPage = useRouteMatch("/feed")?.isExact;

  const shouldRenderMetaFirstAndThenTitle =
    isRenderedOutsideTheSpace ||
    isHomePage ||
    isFeedPage ||
    isSmScreen ||
    usedIn === "minimal-post-modal";

  return (
    <div
      className={classNames("flex items-start justify-between gap-3", {
        "mb-5": isUsedInMinimalTipTapViewModal && isImagePost(post),
      })}
    >
      <div className="flex grow flex-col items-start justify-between space-y-5">
        {post?.flagged_for_approval_at && (
          <BadgeV2
            className="!bg-author-in-review post__headline-tag align mt-1 border-0 align-middle uppercase text-white"
            label={t("in_review")}
          />
        )}
        {shouldRenderMetaFirstAndThenTitle ? (
          <>
            <PostMetaInfo
              isRenderedOutsideTheSpace={isRenderedOutsideTheSpace}
              doesPostHaveCoverImage={doesPostHaveCoverImage}
              space={space}
              tagsLimit={tagsLimit}
              tagsShowMoreLabelVariant={tagsShowMoreLabelVariant}
              wrapperClassName={classNames("mt-1", metaInfoWrapperClassName)}
              hideAuthorHeadline={hideAuthorHeadline}
            />
            <PostName />
          </>
        ) : (
          <>
            <PostName />
            <PostMetaInfo
              isRenderedOutsideTheSpace={isRenderedOutsideTheSpace}
              doesPostHaveCoverImage={doesPostHaveCoverImage}
              space={space}
              tagsLimit={tagsLimit}
              tagsShowMoreLabelVariant={tagsShowMoreLabelVariant}
              wrapperClassName={classNames("mt-1", metaInfoWrapperClassName)}
              hideAuthorHeadline={hideAuthorHeadline}
            />
          </>
        )}
      </div>
      <PostActions
        post={post}
        onChange={onChange}
        refetchPosts={refetchPosts}
        onPostDestroy={onPostDestroy}
        hideShareLink={hideShareLink}
        hidePinnedToTopIcon={hidePinnedToTopIcon}
        hideBookmarkButton={hideBookmarkButton}
      />
    </div>
  );
};
